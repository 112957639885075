.OnlineIndicator {
    display: inline-block;
    margin: 2px;
    padding: 5px;
    border-radius: 50%;
    color: white;
    font-size: small;
}
.OnlineIndicator:first-letter {
    text-transform: uppercase;
}
.OnlineIndicator.authenticated {
    background-color: rgb(0, 140, 0);
}
.OnlineIndicator.online {
    background-color: rgb(240, 240, 0);
}
.OnlineIndicator.offline {
    background-color: rgb(140, 0, 0);
}